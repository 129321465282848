/* @flow */

import React from "react";

import AmexLogo from "icons/payment_methods/amex.svg";
import MastercardLogo from "icons/payment_methods/mastercard.svg";
import VisaLogo from "icons/payment_methods/visa.svg";
import GooglePayIcon from "icons/payment_methods/google-pay.svg";
import MSPayIcon from "icons/payment_methods/ms-wallet.svg";
import ApplePayIcon from "icons/payment_methods/apple-dark.svg";

import styles from "./styles.scss";

const PaymentMethodsCards = (): React$Node => {
  return (
    <div className={styles.cards}>
      <div className={styles.cardsInner}>
        <div><MastercardLogo className={styles.mastercard} /></div>
        <div><VisaLogo className={styles.visa} /></div>
        <div><AmexLogo className={styles.amex} /></div>
        <div><ApplePayIcon className={styles.apple} /></div>
        <div><MSPayIcon className={styles.microsoft} /></div>
        <div><GooglePayIcon className={styles.google} /></div>
      </div>
    </div>
  );
};

export default PaymentMethodsCards;
