/* @flow */

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import useCheckoutSteps from "helpers/use-checkout-steps";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import Logo from "components/AppHeader/Logo";
import ChevronIcon from "icons/chevron-big.svg";
import CustomerServiceIcon from "icons/customer-service.svg";
import SystemMessages from "components/SystemMessages";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const CheckoutHeader = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const history = useHistory();
  const { steps, currentStep } = useCheckoutSteps();

  return (
    <>
      <SystemMessages />
      <div className={cn(className, styles.block)}>
        <Wrapper>
          <div className={styles.content}>
            <div className={styles.left}>
              <Link className={styles.logo} to="/">
                <Logo />
              </Link>

              <div className={styles.steps__desktop}>
                {steps.map(x => (
                  <Link
                    key={x.id}
                    to={x.link}
                    className={cn(styles.step, { [styles.current]: x.id === currentStep.id })}
                  >
                    {`${x.key}. ${x.title}`}
                  </Link>
                ))}
              </div>
            </div>

            <div className={styles.right}>
              <Link to={t("CUSTOMER_SERVICE.LINK")}>
                <CustomerServiceIcon />
              </Link>
            </div>
          </div>

          <div className={styles.steps__mobile}>
            <div className={styles.step}>
              <div className={styles.counter}>
                {currentStep.key} of {steps.length}
              </div>
              <div className={styles.title}>
                {t(`CHECKOUT.STEPS.${currentStep.id.toUpperCase()}.SHORT`)}
              </div>
            </div>

            <Button className={styles.back} onClick={() => history.goBack()}>
              <ChevronIcon />
            </Button>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default CheckoutHeader;
