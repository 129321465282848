/* @flow */

import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";
import LogoIcon from "icons/logo.svg";

type Props = {
  className?: string,
};

const Logo = ({ className, ...props }: Props): React$Node => {
  const t = useTranslate();

  return (
    <div
      {...props}
      className={cn(styles.block, className)}
    >
      <LogoIcon
        alt={t("LOGO.ALT")}
        title={t("LOGO.TITLE")}
        className={styles.logo}
        onClick={() => {}}
      />
    </div>
  );
};

export default Logo;
