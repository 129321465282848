/* @flow */

import React from "react";
import Button from "components/Button";
import Link from "components/Link";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  size?: "small" | "large",
  text: string,
  cta: string,
  smallImage?: { x1: string, x2: string },
  largeImage?: { x1: string, x2: string },
  link: string,
};

const ProductPromotion = ({
  text,
  cta,
  largeImage,
  smallImage,
  link,
  size = "small",
}: Props): React$Node => {
  return (
    <Link to={link} className={styles.block}>
      <picture className={styles.image}>
        {smallImage &&
          <source
            srcSet={smallImage.x1}
            media="(min-width: 674px)"
          />
        }
        {largeImage &&
          <source
            srcSet={largeImage.x1}
            media="(min-width: 674px)"
          />
        }
        <img alt={text} src={largeImage && largeImage.x1} className={styles.innerImage} />
      </picture>
      <div className={styles.content}>
        <div className={styles.textWrapper}>
          <span
            className={cn(styles.text, { [styles[`size__${size}`]]: Boolean(size) })}
          >
            {text && text}
          </span>
        </div>
        <Button variant="primary">{cta && cta}</Button>
      </div>
    </Link>
  );
};

export default ProductPromotion;
