/* @flow */

import type { Order } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", type: "order" }
  | { state: "LOADED", data: ?Order }
  | { state: "ERROR", error: string };

export type OrderResponse = Response<typeof ORDER_RESPONSE, ?Order>;

export type OrderRequest = {
  tag: typeof ORDER_REQUEST,
  loadQuote: boolean,
  type: "order",
};

export const ORDER_RESPONSE: "response/order" = "response/order";
export const ORDER_REQUEST: "request/order" = "request/order";

export const syncOrder = (order: ?Order): OrderResponse => ({
  tag: ORDER_RESPONSE,
  data: order,
});

export const load = (loadQuote: boolean = true): OrderRequest => ({ tag: ORDER_REQUEST, loadQuote, type: "order" });

export const OrderModel: Model<Data, { }, OrderRequest | OrderResponse> = {
  id: "order",
  init: () =>
    updateData(
      { state: "LOADING", type: "order" },
      load()
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case ORDER_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        return updateData({ state: "LOADED", data: msg.data ? msg.data : null });

      case ORDER_REQUEST:
        if (state.state === "LOADED" || state.state === "ERROR") {
          return updateData({ state: "LOADING", type: msg.type }, load(msg.loadQuote));
        }

        break;

      default:
    }
  },
};
