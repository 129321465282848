/* @flow */

import type { Model } from "crustate";
import { updateData } from "crustate";

export type Step =
  | "cart"
  | "shipping"
  | "payment"
  | "overview";

export type SetStep = { tag: typeof SET_STEP, step: Step };

export const SET_STEP: "checkout_step/set_step/request" = "checkout_step/set_step/request";

export const setStep = (step: Step): SetStep => ({
  tag: SET_STEP,
  step,
});

export const CheckoutStepModel: Model<Step, {}, SetStep> = {
  id: "checkout-step",
  init: () => {
    return updateData("cart");
  },
  update: (state, msg) => {
    if (msg.tag === SET_STEP) {
      return updateData(msg.step);
    }
  },
};
