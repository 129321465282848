/* @flow */

import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { Link } from "react-router-dom";
import { QuoteData } from "data";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import useFormat from "helpers/use-format";
import { useUi } from "helpers/ui";
import { MODE } from "state/view-mode";

import CloseIcon from "icons/close-big.svg";
import ChevronIcon from "icons/chevron-big.svg";
import SearchIcon from "icons/search.svg";
import CustomerServiceIcon from "icons/customer-service.svg";
import CartIcon from "icons/cart-empty.svg";

import styles from "./styles.scss";

const Header = (): React$Node => {
  const t = useTranslate();
  const { closeHamburger: close } = useUi();

  return (
    <Wrapper className={styles.header}>
      <span className={styles.heading}>{t("HEADER.NAVIGATION")}</span>
      <Button onClick={() => close(styles.animationDuration)}>
        <CloseIcon className={styles.close} />
      </Button>
    </Wrapper>
  );
};

const TopMenu = (): React$Node => {
  const t = useTranslate();
  const { setModeAndOpenMenu: setMode } = useUi();

  return (
    <div className={styles.topMenu}>
      <Link className={styles.item} to="/all-products">
        {t("ALL_PRODUCTS.TITLE")}
      </Link>

      <span className={styles.item} onClick={() => setMode(MODE.CATEGORIES)}>
        <span>{t("HEADER.CATEGORIES")}</span>
        <ChevronIcon className={styles.chevron} />
      </span>

      <span className={styles.item} onClick={() => setMode(MODE.BRANDS)}>
        <span>{t("HEADER.BRANDS")}</span>
        <ChevronIcon className={styles.chevron} />
      </span>

      <Link className={styles.item} to={t("CUSTOMER_SERVICE.LINK")}>
        <span>
          <CustomerServiceIcon />
          <span>{t("CUSTOMER_SERVICE.TEXT")}</span>
        </span>
      </Link>

      <span className={styles.item} onClick={() => setMode(MODE.SEARCH)}>
        <span>
          <SearchIcon />
          <span>{t("HEADER.SEARCH")}</span>
        </span>
      </span>

      <CartButton />
    </div>
  );
};

const CartButton = (): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const quote = useData(QuoteData).data || null;
  const cartCount = quote?.items.reduce((a, { qty }) => a + qty, 0) || 0;

  return (
    <Link
      className={styles.item}
      title={t("HEADER.CART")}
      to="/checkout/cart"
    >
      <span>
        <CartIcon />
        <span>{t("HEADER.CART")}</span>
      </span>
      <span>
        <span className={styles.grandTotal}>
          {formatPrice(quote?.grandTotal.incVat || 0)}
        </span>
        <span className={styles.cartCount}>&nbsp;({cartCount})</span>
      </span>
    </Link>
  );
};

const HamburgerMenu = ({ children }: { children: React$Node }): React$Node => {
  const {
    hamburgerOpen,
    isHamburgerOpening,
    isHamburgerClosing,
    subNavOpen,
  } = useUi();

  const ref = useRef();

  useEffect(() => {
    const element = ref.current;

    if (hamburgerOpen && !subNavOpen) {
      disableBodyScroll(element);
    }
    else {
      enableBodyScroll(element);
    }

    return () => {
      if (element) {
        enableBodyScroll(element);
      }
    };
  }, [subNavOpen, hamburgerOpen]);

  return (
    <div className={cn(
      styles.block,
      { [styles.open]: hamburgerOpen },
      { [styles.isOpening]: isHamburgerOpening },
      { [styles.isClosing]: isHamburgerClosing },
      { [styles.subNavOpen]: subNavOpen }
    )}
    >
      <div ref={ref} className={styles.hamburgerMenu}>
        <Header />

        <TopMenu />
      </div>

      {children}
    </div>
  );
};

export default HamburgerMenu;
