/* @flow */

import React, { useContext } from "react";
import { SuccessView as SuccessViewExt } from "@crossroads/shop-views";
import { AnalyticsContext } from "@crossroads/analytics";
import { useClient } from "entrypoint/shared";
import { OrderData } from "data";
import useFormat from "helpers/use-format";
import { useData } from "crustate/react";
import { load as loadOrder } from "state/order";
import CheckIcon from "icons/checkmark-success.svg";

const Success = (): React$Node => {
  const orderData = useData(OrderData);
  const { formatPrice } = useFormat();
  const analytics = useContext(AnalyticsContext);

  return (
    <SuccessViewExt
      orderData={orderData}
      useClient={useClient}
      analytics={analytics}
      formatPrice={formatPrice}
      additionalField="NONE"
      loadOrder={loadOrder}
      mapComponent={null}
      CheckIcon={<CheckIcon style={{ color: "#fff" }} />}
    />
  );
};

export default Success;
