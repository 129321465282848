/* @flow */

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useBreakpoints } from "helpers/use-breakpoints";

import styles from "./styles.scss";

type Props = {
  className?: string,
  items: Array<any>,
};

// Fisher-Yates shuffle
const shuffle = array => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

const Categories = ({ items, className = "" }: Props): React$Node => {
  const [sortedItems, setSortedItems] = useState([]);
  const { getNumberOfDisplayedItems } = useBreakpoints(styles);

  const getAmountOfDisplayedCategories = () => {
    const breakpointColumnMapping = {
      mini: 2,
      small: 3,
      medium: 5,
    };
    return getNumberOfDisplayedItems(breakpointColumnMapping, 6);
  };

  useEffect(() => {
    setSortedItems(shuffle(items.slice()));
  }, [setSortedItems]);

  return items.length > 0 ? (
    <div className={cn(styles.categories, className)}>
      {sortedItems
        .slice(0, getAmountOfDisplayedCategories())
        .map((x, i) => (
          <div key={i}>
            <Link
              to={{
                pathname: x.url,
                state: { hint: { type: "category", category: x } },
              }}
              className={styles.category}
            >
              <picture className={styles.image}>
                <source srcSet={`${x.image.x1}, ${x.image.x2} 2x`} />
                <img src={x.image.x1} alt={x.name} />
              </picture>
              <span>{x.name}</span>
            </Link>
          </div>
        ))}
    </div>
  ) : null;
};

export default Categories;
