/* @flow */

import type { Node as ReactNode } from "react";

import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { usePreserveScrollPosition } from "react-router-on-navigation";
import AppHeader from "components/AppHeader";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { ViewModeData, CmsData } from "data";
import { UiProvider, useUi } from "helpers/ui";
import { MODE } from "state/view-mode";
import { useHistory } from "react-router";
import useCookie from "helpers/use-cookie";
import CookieConsent from "components/CookieConsent";
import { NotificationBar, AppFooter, ButtonLink } from "@crossroads/ui-components";
import Logo from "components/AppHeader/Logo";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  children: ReactNode,
};

const MATCH_BUY_AND_TRANSFER = /buy-and-transfer-points-wip\/(?!success)[^/]+\/?$/g;

const App = ({ children }: Props): React$Node => {
  const container = useRef(null);
  const history = useHistory();
  const t = useTranslate();
  const onCheckout = history.location.pathname.includes("checkout") && history.location.pathname !== "/checkout/success";
  const onCountry = history.location.pathname.includes("account/country");
  const onPurchasePoints = history.location.pathname.match(MATCH_BUY_AND_TRANSFER);
  const { info } = useContext(StoreInfoContext);
  const cookieOpts = { domain: `.${typeof window !== "undefined" ? window.location.host.replace(/:\d+$/, "") : ""}`, expires: 3650 };
  const [consent, setConsent, consentRead] = useCookie("cookie-consent", "initial", cookieOpts);
  const [toggleCookieDialogue, setToggleCookieDialogue] = useState(false);
  const [hideNotificationBar, setHideNotificationBar] = useState(false);

  usePreserveScrollPosition(history);

  const footerContentTerms = () => (
    <div className={styles.termsField}>
      <div>
        <Link className={styles.link} to="/terms">
          {t("FOOTER.TERMS")}
        </Link>
        &nbsp;|&nbsp;
        <Link className={styles.link} to="/privacy-policy">
          {t("FOOTER.PRIVACY_POLICY")}
        </Link>
        &nbsp;|&nbsp;
        <ButtonLink
          className={styles.link}
          onClick={() => setToggleCookieDialogue(true)}
        >
          {t("FOOTER.COOKIES")}
        </ButtonLink>
      </div>
      <p dangerouslySetInnerHTML={{ __html: t("FOOTER.COPYRIGHT", { year: new Date().getFullYear() }) }} />
    </div>
  );

  return (
    <ViewModeData.Provider>
      <CmsData.Provider url="cookie-consent">
        <UiProvider>
          <div
            ref={container} className={cn(
              styles.block,
              { [styles.onCheckout]: onCheckout },
              { [styles.onCountry]: onCountry },
              { [styles.onPurchasePoints]: onPurchasePoints }
            )}
          >
            <Helmet
              titleTemplate={`${info.titlePrefix || ""} %s ${info.titleSuffix || ""}`}
              link={[
                { rel: "icon", type: "image/png", size: "16x16", href: "/assets/browser/favicon-16x16.png" },
                { rel: "icon", type: "image/png", size: "32x32", href: "/assets/browser/favicon-32x32.png" },
                { rel: "manifest", href: "/assets/browser/site.webmanifest" },
                { rel: "mask-icon", href: "/assets/browser/safari-pinned-tab.svg", color: "#da2911" },
              ]}
              meta={[
                { name: "msapplication-TileColor", content: "#ffffff" },
                { name: "theme-color", content: "#ffffff" },
              ]}
              htmlAttributes={{ lang: info.locale.slice(0, 2) }}
            />
            <div className={styles.top}>
              {(!onCountry && !onPurchasePoints && !onCheckout) &&
                <AppHeader />
              }
              {(!onCheckout) &&
                <div className={cn(styles.height, { [styles.onCheckout]: onCheckout })} />
              }

              {consentRead &&
                <div className={styles.notices}>
                  <CookieConsent
                    isVisible={consentRead && (consent === "initial" || toggleCookieDialogue)}
                    consent={consent}
                    setConsent={setConsent}
                    consentRead={consentRead}
                    setToggleCookieDialogue={setToggleCookieDialogue}
                  />
                </div>
              }

              <div>
                {children}
              </div>
            </div>

            {(!onCheckout) && (
              <AppFooter
                hasVisibleHelpColumn
                className={styles.footer}
                activeLinks={["start", "categories", "brands", "search"]}
                contentBranding={() => <Logo className={styles.logo} />}
                contentTerms={footerContentTerms}
                useUi={useUi}
                MODE={MODE}
              />
            )}

            {info.popUp && info.popUp.body !== "" && !hideNotificationBar &&
              <NotificationBar
                className={styles.notificationBar}
                text={info.popUp.body}
                onClose={() => setHideNotificationBar(true)}
              />
            }
          </div>
        </UiProvider>
      </CmsData.Provider>
    </ViewModeData.Provider>
  );
};

export default App;
