/* @flow */

import { useContext } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";

type UseFormatReturnValue = {
  formatPriceLong: (price: number | string, currency: ?string) => string,
  formatPrice: (price: number | string) => string,
  formatDate: (date: string) => string,
};

// Finds groups of three digits in a number.
const MATCH_NUMBER_SET = /\B(?=(\d{3})+(?!\d))/g;

// Formats a number-as-string into 12 345.67 format.
export const formatNumber = (n: string): string =>
  n.replace(".", ",").replace(MATCH_NUMBER_SET, " ");

const formatDate = (date: string): string => {
  const dt = new Date(date);
  return `${dt.getFullYear()}-${("00" + (dt.getMonth() + 1)).slice(-2)}-${("00" + dt.getDate()).slice(-2)}`;
};

const useFormat = (): UseFormatReturnValue => {
  const t = useTranslate();
  const { info } = useContext(StoreInfoContext);
  const currencyCode = info.baseCurrencyCode;

  return {
    formatPriceLong: (price: number | string, currency: ?string) => {
      const curr = currency ? currency : currencyCode;
      return formatNumber(Number(Number.parseFloat(String(price)).toFixed(curr === "EUR" ? 2 : 0)).toString()) + " " + curr;
    },
    formatPrice: (price: number | string) =>
      // Max 2 decimals, and skip any trailing zeroes
      formatNumber(Number(Number.parseFloat(String(price)).toFixed(currencyCode === "EUR" ? 2 : 0)).toString()) + " " + t(`CURRENCY_SHORT.${currencyCode}`),
    formatDate,
  };
};

export default useFormat;
